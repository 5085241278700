<template>
  <n-card
    style="width: 600px; text-align: justify;"
    title="Privatumo ir slapukų politika"
    size="huge"
    role="dialog"
    :segmented="{ content: true, footer: 'soft' }"
  >

    Mes, interneto svetainės "E-vedlys" (toliau - Svetainė) komanda - gerbdama šios Svetainės lankytojų privatumą ir teisę į duomenų apsaugą, įsipareigojame užtikrinti Jūsų duomenų saugumą ir įgyvendinti Jūsų teises. Prašome susipažinti su Svetainės privatumo politika (toliau – Privatumo politika).
    <n-divider />

    Privatumo politika reglamentuoja pagrindinius Svetainės lankytojų asmens duomenų rinkimo, naudojimo, saugojimo principus bei tvarką. Privatumo politikos sąlygos taikomos tuomet, kai Jūs norite pasiekti Svetainėje skelbiamą turinį ir (ar) paslaugas naudodami įvairius įrenginius (kompiuterį, mobilųjį telefoną, planšetę, išmanųjį televizorių ar kt.). Patvirtiname, kad Jūsų pateiktus asmens duomenis tvarkysime laikydamiesi galiojančių Europos Sąjungos ir Lietuvos Respublikos teisės aktų reikalavimų. Jūs turite teisę susipažinti su savo asmens duomenimis ir kaip jie yra tvarkomi, reikalauti ištaisyti, papildyti ar sunaikinti pateiktus asmens duomenis, taip pat sustabdyti jų tvarkymo veiksmus (atšaukti savo sutikimą). Taip pat turite teisę reikalauti, kad asmens duomenų valdytojas apribotų asmens duomenų tvarkymą, teisę į duomenų perkėlimą, pateikti skundą Valstybinei duomenų apsaugos inspekcijai (kontaktiniai duomenys pateikti interneto svetainėje www.ada.lt) ir nesutikti su pateiktų asmens duomenų tvarkymu.

    <n-divider />
    PRIVATUMO POLITIKOJE VARTOJAMOS SĄVOKOS:
    <n-divider />

    Asmens duomenys – bet kokia informacija apie fizinį asmenį, kurio tapatybė nustatyta, arba kurio tapatybę galima nustatyti. Fizinio asmens tapatybę tiesiogiai arba netiesiogiai galima nustatyti pagal identifikatorių, tokį kaip vardas ir pavardė, asmens identifikavimo numeris, buvimo vietos duomenys ir interneto identifikatorius, arba pagal vieną ar kelis to fizinio asmens fizinės, fiziologinės, genetinės, psichinės, ekonominės, kultūrinės ar socialinės tapatybės požymius.
    <br><br>
    Duomenų subjektas – Svetainės lankytojas (fizinis asmuo), kurio asmens duomenis renka ir tvarko Duomenų valdytojas. Duomenų subjekto sutikimas – bet koks laisva valia duotas, konkretus ir nedviprasmiškas tinkamai informuoto Duomenų subjekto valios išreiškimas pareiškimu arba vienareikšmiais veiksmais, kuriais jis sutinka, kad būtų tvarkomi su juo susiję asmens duomenys.
    <br><br>
    Duomenų tvarkymas – automatizuotomis arba neautomatizuotomis priemonėmis atliekama operacija ar operacijų seka, kurių metu naudojami asmens duomenys ar asmens duomenų rinkiniai, taip pat šių duomenų rinkimas, įrašymas, rūšiavimas, sisteminimas, saugojimas, adaptavimas ar keitimas, išgavimas, susipažinimas, naudojimas, atskleidimas persiunčiant, platinant ar kitu būdu sudarant galimybę jais naudotis, sugretinimas ar sujungimas su kitais duomenimis, apribojimas, ištrynimas arba sunaikinimas.
    <br><br>
    Duomenų tvarkytojas – fizinis arba juridinis asmuo, valdžios institucija, agentūra ar kita įstaiga, kuri Duomenų valdytojo vardu tvarko asmens duomenis.
    <br><br>
    Duomenų valdytojas – interneto svetainės "E-vedlys" komanda. Komanda užtikrina, kad asmens duomenys, reikalingi Svetainės veikimo užtikrinimui būtų tvarkomi laikantis duomenų valdytojams taikomų asmens duomenų apsaugos reikalavimų.
    <br><br>

    <n-divider />
    REGISTRACIJA
    <n-divider />

    Jeigu Jūs nesutinkate su Taisyklėmis, šia Politika ar atskiromis jų sąlygomis, mes, deja, negalėsime suteikti Jums galimybės naudotis E-vedlys svetainės paslaugomis (toliau – Paslaugos).
    <br><br>
    Registruodamiesi ir sutikdami su Politika bei Taisyklėmis, Jūs pateikiate mums anketoje nurodytus asmens duomenis. Registruodamiesi Jūs patvirtinate, kad jūsų nurodyti asmens duomenys yra tikslūs. Asmens duomenų patekimas yra būtinas tam, kad galėtumėte naudotis Paslaugomis. Mes neprisiimame atsakomybės už netikslius, neišsamius ar klaidingai pateikus Jūsų duomenis.
    <br><br>
    Duomenų tvarkymo tikslas: registracijos E-vedlys svetainėje priežiūra ir administravimas.
    <br><br>
    Pagal Jūsų pateiktus duomenis mes svetainėje  sukuriame unikalų profilį, pagal kurį identifikuosime Jus kaip registruotą svetainės narį.

    <n-divider />
    ASMENS DUOMENŲ GAVĖJAI
    <n-divider />

    Saugodami Jūsų asmens duomenis įsipareigojame jų neperduoti jokioms nesusijusioms trečiosioms šalims, išskyrus atvejus, kai:
    <br><br>
    Yra gautas Jūsų sutikimas asmens duomenų atskleidimui.
    <br><br>
    Duomenis reikia pateikti teisėsaugos institucijoms Lietuvos Respublikos teisės aktų numatyta tvarka. Jūsų pateikti asmens duomenys į trečiąsias valstybes ar tarptautines organizacijas nebus perduodami. Jūsų asmens duomenis saugosime tiek, kiek to reikalauja teisės aktai.
    <br><br>

    <n-divider />
    INTERNETO SVETAINĖS SLAPUKŲ POLITIKA BENDROSIOS NUOSTATOS
    <n-divider />

    Mes, gerbdami šios interneto svetainės lankytojų privatumą ir teisę į duomenų apsaugą, įsipareigojame užtikrinti Jūsų duomenų saugumą ir įgyvendinti Jūsų teises. Siekiame, kad Svetainės turinys ir funkcijos kuo geriau atitiktų Jūsų poreikius, naršymas Jums būtų patogesnis ir galėtumėte gauti norimą informaciją ir (ar) paslaugas. Informuojame, kad Svetainėje naudojame Slapukus. Jie padeda atpažinti Jus kaip kitų interneto svetainių lankytoją, išsaugo Jūsų lankymosi Svetainėje istoriją ir pasirinktus nustatymus, pagal tai pritaiko Jums turinį. Daugiau informacijos apie Slapukus galite rasti adresu: cookiesandyou.com.
    <br><br>
    Slapukas (angl. cookie) – nedidelė teksto rinkmena, kurią svetainė išsaugo Jūsų kompiuteryje arba mobiliajame įrenginyje, kai joje apsilankote.
    <br><br>
    Slapuko paskirtis – suteikti Svetainei galimybę tam tikrą laiką įsiminti naudotojo pasirinkimą (pavyzdžiui, kalbą, nustatymus ir kt.). Tikimės, kad taip Jus pasieks naudingas ir Jums rūpimas turinys. Mūsų svetainėje gali būti nuorodų į kitų asmenų, įmonių ar organizacijų interneto tinklapius. Pažymime, kad nesame atsakingi už tokių interneto tinklapių turinį ar jų taikomus privatumo užtikrinimo principus. Todėl visada prašome pasidomėti tų svetainių Privatumo politika. Svetainėje naudojami šie Slapukai:
    <br><br>
    Būtinieji techniniai Slapukai, skirti užtikrinti būtiną Svetainės veikimą ir funkcionalumą.

    <n-divider />
    BAIGIAMOSIOS NUOSTATOS
    <n-divider />

    Už Slapukų politikos nuostatų laikymąsi atsakoma teisės aktų nustatyta tvarka. Pasikeitus Slapukų politikoje esančiam turiniui, apie tai informuosime asmenis, kurių duomenys yra tvarkomi. Apie Slapukų politikos pakeitimus bus paskelbiama Svetainėje arba siunčiamas pranešimas elektroninio pašto adresu. Dėkojame, kad skyrėte laiko perskaityti ir susipažinti su mūsų Slapukų politika. Jeigu turite klausimų, prašymų, kitų pastebėjimų, kviečiame susisiekti su mumis ir juos išsakyti. Jūsų elektroninių laiškų lauksime adresu vpt.evedlys@gmail.com

  </n-card>
</template>

<script>
import { defineComponent, } from 'vue'

export default defineComponent({
  components: {
  },
  setup() {
    return {
    }
  }
})
</script>

<style>
</style>
