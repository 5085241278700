<template>
  <n-card :bordered="false">
    <n-space vertical>
    <n-form
      :label-width="80"
      :model="model"
      :rules="rules"
      size="medium"
      ref="formRef"
    >
      <n-form-item label="Vardas" path="firstName">
        <n-input v-model:value="model.firstName" placeholder="" />
      </n-form-item>
      <n-form-item label="Pavardė" path="lastName">
        <n-input v-model:value="model.lastName" placeholder="" />
      </n-form-item>
      <n-form-item label="El. paštas" path="email">
        <n-input v-model:value="model.email" placeholder="" />
      </n-form-item>
      <n-form-item label="Organizacija" path="company">
        <n-input v-model:value="model.company" placeholder="" />
      </n-form-item>
      <n-form-item label="Slaptažodis" path="password">
        <n-input-group>
          <n-input
            v-model:value="model.password"
            @input="handlePasswordInput"
            type="password"
            show-password-on="mousedown"
            @keydown.enter.prevent
            placeholder=""
          />
          <n-tooltip trigger="hover" :style="{ maxWidth: '400px' }">
            <template #trigger>
              <n-button>?</n-button>
            </template>
            Slaptažodį privalo sudaryti bent 8 ženklai, jame privalo būti bent vienas skaičius ir didžioji raidė
          </n-tooltip>
        </n-input-group>
      </n-form-item>
      <n-form-item first label="Pakartokite slaptažodį" path="confirmPassword" ref="rPasswordFormItemRef">
        <!-- :disabled="!model.password" -->
        <n-input
          v-model:value="model.confirmPassword"
          type="password"
          @keydown.enter.prevent
          placeholder=""
          show-password-on="mousedown"
        />
      </n-form-item>

      <n-modal v-model:show="showTerms">
        <termsAndConditions />
      </n-modal>
      <n-form-item path="acceptTerms">
        <!-- https://bottlery.eu/privatumo-politika/ -->
        <template #label> <n-button text tag="a" @click="showTerms = !showTerms">Sistemos naudojimo taisyklės</n-button> </template>
        <n-checkbox v-model:checked="model.acceptTerms"> Sutinku su taisyklėmis</n-checkbox>
      </n-form-item>

      <n-form-item>
        <n-spin size="small" :show="data.disableButton">
          <n-button @click="handleClick">Registruotis</n-button>
        </n-spin>
      </n-form-item>
    </n-form>
    </n-space>
  </n-card>
  <countDownModal
    :fn="() => router.go()"
    :timer="10"
    title="Registracija beveik baigta! ⏳"
    body="Į nurodytą el. paštą išsiuntėme Jums laišką. Sekite instrukcijas laiške."
    :show="showModal"
  />
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { handleServerResponse } from '@/shared/serverResponseHandler'
import countDownModal from '@/components/CountDownModal.vue'
import termsAndConditions from '@/pages/auth/termsAndConditions.vue'

export default defineComponent({
  components: {
    countDownModal,
    termsAndConditions,
  },
  setup () {
    const dataRef = ref({disableButton: false})
    const store = useStore()
    const showModalRef = ref(false)
    const formRef = ref(null)
    const rPasswordFormItemRef = ref(null)
    const message = useMessage()
    const modelRef = ref({
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      password: '', // setting to empty string, so that length valdation does not fail at blank
      confirmPassword: null,
      // will have to add this later on
      acceptTerms: false,
    })
    function validatePasswordStartWith (rule, value) {
      return (
        modelRef.value.password &&
        modelRef.value.password.startsWith(value) &&
        modelRef.value.password.length >= value.length
      )
    }
    function validatePasswordSame (rule, value) {
      return value === modelRef.value.password
    }
    function validatePasswordComplexity (rule, value) {
      var hasNumber = /\d/;
      var hasUpperCase = /[A-Z]/
      var hasLowerCase = /[a-z]/
      return (
        value.length >= 8 &&
        hasNumber.test(value) &&
        hasUpperCase.test(value) &&
        hasLowerCase.test(value)
      )
    }
    return {
      data: dataRef,
      formRef,
      rPasswordFormItemRef,
      model: modelRef,
      rules: {
        firstName: [
          {
            required: true,
            message: 'Privaloma uzpildyti',
            trigger: 'input'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Privaloma uzpildyti',
            trigger: 'input'
          }
        ],
        email: [
          {
            required: true,
            message: 'Privaloma uzpildyti',
            trigger: 'blur'
          }
        ],
        company: [
          {
            required: true,
            message: 'Privaloma uzpildyti',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          },
          {
            validator: validatePasswordComplexity,
            message: 'Slaptažodis per paprastas',
            trigger: 'input'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          },
          {
            validator: validatePasswordStartWith,
            message: 'Slaptažodžiai nesutampa',
            trigger: 'input'
          },
          {
            validator: validatePasswordSame,
            message: 'Slaptažodžiai nesutampa',
            trigger: ['blur', 'password-input']
          }
        ],
        acceptTerms: [
          {
            required: true,
            message: 'Privaloma pažymėti',
            // trigger: 'change',
          },
          {
            validator: (rule, value) => { return value },
            message: 'Privaloma sutikti su sąlygomis'
          }
        ]
      },
      handlePasswordInput () {
        if (modelRef.value.confirmPassword) {
          rPasswordFormItemRef.value.validate({ trigger: 'password-input' })
        }
      },
      handleClick (e) {
        e.preventDefault()
        dataRef.value.disableButton = true
        formRef.value.validate((errors) => {
          // first check front end validation
          if (!errors) {
            // call auth api
            console.log('no errors lets call backend')
            store.dispatch('auth/register', modelRef.value)
              .then(resp => {
                const responseStatusMap = {
                  200: function() {
                    message.success(resp.data.message, { duration: 8000 })
                    showModalRef.value = true
                  },
                  500: function() {
                    message.warning(500)
                 },
                  400: function() {
                    message.warning(400)
                    message.warning(resp.data.message, { duration: 8000 })
                  }
                }
                handleServerResponse(resp, responseStatusMap)
              })
              .then(() => {
                // lets enable the button
                dataRef.value.disableButton = false
              })
          } else {
            console.log(errors)
            message.error('Nepavyko: patikrinkite įvesties laukelius')
            dataRef.value.disableButton = false
          }
        })
      },

      showModal: showModalRef,
      showTerms: ref(false),
      router: useRouter(),
    }
  }
})
</script>

<style>
</style>
