<template>
  <n-layout content-style="padding: 16px" class="noselect">
    <n-space vertical align="center">
      <n-h1 class="floating" style="font-size: 80px;" disabled>🎓</n-h1>
    </n-space>
    <n-space vertical align="center">
      <n-card :hoverable="true">
      <n-space vertical>
      <n-card :bordered="false" class="grow">
        <n-h1 prefix="bar" type="success">VPT mokymų sistema</n-h1>
        <n-h4 prefix="bar" type="primary">E-vedlys</n-h4>
        <n-h4 prefix="bar" type="warning">Prisijunkite arba registruokitės žemiau</n-h4>
      </n-card>
      <br>
      <!-- Over here we should add loading screen and check if client is already logged in.
      jwt token? then refresh token? if all is fine, redirect -->
      <n-spin size="large" :show="data.isLoading">
        <n-tabs type="line" justify-content="space-evenly">
          <n-tab-pane name="Prisijunkite" tab="Prisijunkite">
            <loginCard />
          </n-tab-pane>
          <n-tab-pane name="Registracija" tab="Registracija">
            <registerCard />
          </n-tab-pane>
        </n-tabs>
      </n-spin>
      </n-space>
      </n-card>
    </n-space>
  </n-layout>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import registerCard from '@/pages/auth/register_card.vue'
import loginCard from '@/pages/auth/login_card.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useMessage } from 'naive-ui'
import { handleServerResponse } from '@/shared/serverResponseHandler'

export default defineComponent({
  components: {
    registerCard,
    loginCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const message = useMessage()
    const dataRef = ref({isloading: true})
    onMounted(() => {
      // check if token is expired
      // console.log(store.getters['auth/getAuthData'])
      const exp = store.getters['auth/getAuthData'].tokenExp

      // check if token exists
      // return if not (no need to call any endpoints)
      if (!exp) {
        dataRef.value.isLoading = false
        return
      }

      // check if current token is expired or not
      if (Date.now() >= exp * 1000) {
        // in this case try acquireing new token with
        // refresh token stored in cookies
        // simply invoke store.
        console.log('token is expired, will try refreshToken')
        store.dispatch('auth/useRefreshToken')
        .then(resp => {
          const responseStatusMap = {
            200: function() {
              console.log('got new auth data', 200)
              dataRef.value.isLoading = false
              message.success('Sveiki sugrįžę!')
              router.push({ path: '/docs' })
            },
            400: function() {
              dataRef.value.isLoading = false
            }
          }
          handleServerResponse(resp, responseStatusMap)
        })
      } else {
        console.log('token not expired, user is logged in')
        message.success('Sveiki sugrįžę!')
        store.commit('auth/setLoginStatus', true)
        router.push({ path: '/docs' })
      }
      // console.log(store.getters['auth/getAuthData'])
    })
    return {
      data: dataRef
    }
  }
})
</script>

<style>

.grow { transition: all .4s ease-in-out; }
.grow:hover { transform: scale(1.05); }

.floating {
    animation-name: floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    /* margin-left: 30px; */
    /* margin-top: 5px; */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }
}

</style>
