<template>
  <n-card :bordered="false">
    <n-space vertical>
      <n-form
        :label-width="80"
        :model="model"
        :rules="rules"
        size="medium"
        ref="formRef"
      >
        <n-form-item label="E. paštas" path="email">
          <n-input v-model:value="model.email" placeholder="" />
        </n-form-item>
        <n-form-item label="Slaptažodis" path="password">
          <n-input placeholder="" type="password" v-model:value="model.password" show-password-on="mousedown"/>
        </n-form-item>
      </n-form>
      <n-space horizontal>
        <n-spin size="small" :show="data.disableButton">
          <n-button @click="handleClick">Prisijungti</n-button>
        </n-spin>
      </n-space>
      <forgotCard text="Pamiršote slaptažodį?"/>
    </n-space>
  </n-card>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import forgotCard from '@/pages/auth/forgot_card.vue'
import { handleServerResponse } from '@/shared/serverResponseHandler'

export default defineComponent({
  components: {
    forgotCard,
  },
  setup () {
    const dataRef = ref({disableButton: false})
    const store = useStore()
    const router = useRouter()
    const formRef = ref(null)
    const message = useMessage()
    const modelRef = ref({
      email: '',
      password: ''
    })

    return {
      data: dataRef,
      formRef,
      model: modelRef,
      rules: {
        email: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          }
        ]
      },
      handleClick (e) {
        // lets disable the button until the calls are finished
        dataRef.value.disableButton = true
        e.preventDefault()
        formRef.value.validate((errors) => {
          // first check front end validation
          if (!errors) {
            // call auth api
            console.log('no errors lets call backend')
            store.dispatch('auth/login', modelRef.value)
              .then(resp => {
                const responseStatusMap = {
                  200: function() {
                    // message.success(200)
                    message.success('Sveiki sugrįžę!')
                    console.log(store.getters['auth/getAuthData'])
                    router.push({ path: '/docs' })
                  },
                  400: function(text) {
                    message.warning(400)
                    message.warning(text)
                  },
                  500: function(text) {
                    message.warning(500)
                    message.warning(text)
                  }
                }
                handleServerResponse(resp, responseStatusMap)
              })
              .then(() => {
                // lets enable the button
                dataRef.value.disableButton = false
              })
          } else {
            console.log(errors)
            message.error('Nepavyko: patikrinkite įvesties laukelius')
            dataRef.value.disableButton = false
          }
        })
      }
    }
  }
})
</script>

<style>
</style>
